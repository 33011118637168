import { Helmet } from "react-helmet";
import stl from "./Home.module.css";
import { BsTelegram, BsTwitterX } from "react-icons/bs";
import { BiLogoTelegram } from "react-icons/bi";
import { FaSackDollar } from "react-icons/fa6";
import { motion as m, useScroll, useTransform } from "framer-motion";
import { useEffect, useRef, useState, useCallback } from "react";
import Marquee from "react-fast-marquee";
import { FaRegCopy } from "react-icons/fa6";
import { LuScrollText } from "react-icons/lu";
import { FaChartColumn } from "react-icons/fa6";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

const Home = () => {
  const REACT_APP_TWITTER_URL = "https://x.com/BibbleonSol";
  const REACT_APP_TELEGRAM_URL = "https://t.me/Bibblesolana";
  const REACT_APP_CHART =
    "https://dexscreener.com/5SG1UvRTGPGG5PmUSxzyEUgKrhZghPGFFLS2x7PRpump";
  const REACT_APP_BUY =
    "https://jup.ag/swap/SOL-5SG1UvRTGPGG5PmUSxzyEUgKrhZghPGFFLS2x7PRpump";
  const REACT_APP_CA = "5SG1UvRTGPGG5PmUSxzyEUgKrhZghPGFFLS2x7PRpump";
  const REACT_APP_LORE = "https://hero.fandom.com/wiki/Bibble";

  const wrapRef = useRef(null);
  const [currentTime, setCurrentTime] = useState("");
  const { scrollYProgress } = useScroll({
    target: wrapRef,
    offset: ["0 1", "1.33 1"],
  });
  const scaleProgress2 = useTransform(scrollYProgress, [0, 1], [0.9, 1]);
  const opacityProgress = useTransform(scrollYProgress, [0, 0.8], [0.3, 1]);
  const [startOpen, setStartOpen] = useState(false);
  const [openPaint, setOpenPaint] = useState(false);
  const [openSolitaire, setOpenSolitaire] = useState(false);
  const [mahjongOpen, setMahjongOpen] = useState(false);
  const [doomOpen, setDoomOpen] = useState(false);
  const [barbieFanOpen, setBarbieFanOpen] = useState(false);

  const calculateTime = useCallback(() => {
    let now = new Date();
    let hours = now.getHours();
    let minutes = now.getMinutes();

    let amOrPm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;

    minutes = minutes < 10 ? "0" + minutes : minutes;

    let timeInPMFormat = hours + ":" + minutes + " " + amOrPm;

    return timeInPMFormat;
  }, []);

  useEffect(() => {
    setInterval(() => {
      setCurrentTime(calculateTime());
    }, 1000);
  }, [setCurrentTime, calculateTime]);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(REACT_APP_CA)
      .then(() => {
        alert("$CA Copied");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <div className={stl.home}>
      <nav>
        <button onClick={() => window.open(REACT_APP_BUY, "_blank")}>
          <FaSackDollar />
          Buy
        </button>
        <button onClick={() => window.open(REACT_APP_TELEGRAM_URL, "_blank")}>
          <BiLogoTelegram /> Telegram
        </button>
        <button onClick={() => window.open(REACT_APP_TWITTER_URL, "_blank")}>
          <BsTwitterX /> Twitter
        </button>
      </nav>
      <Helmet>
        <title>Bibble</title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="Bibble" />
        <meta name="twitter:creator" content="@Bibble_SOL" />
        <meta property="og:url" content="https:/bibble.xyz" />
        <meta property="og:title" content="Bibble on Solana" />
        <meta
          property="og:description"
          content="Bibble is Solana's friendliest Memecoin. Through love and kindness will he conquer."
        />
        {/* <meta
          property="og:image"
          content="https://bibble.xyz/images/Card.webp"
        /> */}
      </Helmet>
      <header className={stl.header}>
        <h1 className={stl.hero}>Bibble</h1>
        <h2 className={stl.subHero}>
          The Guardian of The
          <span className={stl.solana}>Blockchain</span>
        </h2>
        <img src="../BG.jpeg" alt="BG" className={stl.bg} />
      </header>
      <main>
        <section className={stl.mc}>
          <div className={stl.boomWrap}>
            <img src="../Error.webp" alt="error" className={stl.error} />
            <img src="../Boom.png" alt="Boom" className={stl.boom} />
          </div>
          <m.img
            src="../Milady.webp"
            alt="Milady"
            className={stl.milady}
            style={{ scale: scaleProgress2, opacity: opacityProgress }}
          />
          <img src="../MC.gif" alt="BG" className={stl.bg} />
        </section>
        <section className={stl.disney}>
          <h3 className={stl.disneyHero}>Bibble</h3>
          <h4 className={stl.disneyHero2}>Tweaking Straight To 1B MC</h4>
          <m.img
            src="../Xan.webp"
            alt="Xan"
            className={stl.xan}
            // style={{ scale: scaleProgress, opacity: opacityProgress }}
          />
          <img src="../Disney.webp" alt="BG" className={stl.bg} />
        </section>
        <section className={stl.pyramid}>
          <div className={stl.centerBox}>
            <img src="../Bibfly.png" alt="Bibfly" className={stl.bibFly} />
            <span className={stl.pyramidSpan}>Prepare To Get Bibbled!</span>
            <Marquee speed={400} className={stl.marquee1}>
              <img src="../Stars.png" alt="Solana" className={stl.solLogo} />
              <img src="../Stars.png" alt="Solana" className={stl.solLogo} />
              <img src="../Stars.png" alt="Solana" className={stl.solLogo} />
              <img src="../Stars.png" alt="Solana" className={stl.solLogo} />
              <img src="../Stars.png" alt="Solana" className={stl.solLogo} />
              <img src="../Stars.png" alt="Solana" className={stl.solLogo} />
              <img src="../Stars.png" alt="Solana" className={stl.solLogo} />
              <img src="../Stars.png" alt="Solana" className={stl.solLogo} />
            </Marquee>
            <Marquee speed={400} className={stl.marquee2} direction="left">
              <img src="../Stars.png" alt="Solana" className={stl.solLogo} />
              <img src="../Stars.png" alt="Solana" className={stl.solLogo} />
              <img src="../Stars.png" alt="Solana" className={stl.solLogo} />
              <img src="../Stars.png" alt="Solana" className={stl.solLogo} />
              <img src="../Stars.png" alt="Solana" className={stl.solLogo} />
              <img src="../Stars.png" alt="Solana" className={stl.solLogo} />
              <img src="../Stars.png" alt="Solana" className={stl.solLogo} />
              <img src="../Stars.png" alt="Solana" className={stl.solLogo} />
            </Marquee>
            <Marquee speed={400} className={stl.marquee3} direction="left">
              <img src="../Stars.png" alt="Solana" className={stl.solLogo} />
              <img src="../Stars.png" alt="Solana" className={stl.solLogo} />
              <img src="../Stars.png" alt="Solana" className={stl.solLogo} />
              <img src="../Stars.png" alt="Solana" className={stl.solLogo} />
              <img src="../Stars.png" alt="Solana" className={stl.solLogo} />
              <img src="../Stars.png" alt="Solana" className={stl.solLogo} />
              <img src="../Stars.png" alt="Solana" className={stl.solLogo} />
              <img src="../Stars.png" alt="Solana" className={stl.solLogo} />
            </Marquee>
          </div>
          <img src="../Barbie.jpg" alt="Barbie" className={stl.barbie} />
        </section>
        <section className={stl.contestants}>
          <div className={stl.heroDropdown}>
            <h1 className={stl.hero2}>Ready to conquer the leaderboards</h1>
          </div>
          <div className={stl.midBLock}>
            <div className={stl.imgWrapper}>
              <div className={stl.bathwrapper}>
                <img
                  src="../Minibanner.webp"
                  alt="Bibble"
                  className={stl.bathing}
                />
              </div>
              <h2 className={stl.turbotitle}>Bibble</h2>
            </div>
            <h2 className={stl.vs}>VS</h2>
            <div className={stl.innerRight}>
              <div className={stl.rightGrid}>
                <div className={stl.block}>
                  <img
                    src="../CMC.png"
                    alt="Coinmarketcap"
                    className={stl.cmc}
                  />
                  CoinMarketCap
                </div>
                <div className={stl.block}>
                  <img
                    src="../Coingecko.webp"
                    alt="Coingecko"
                    className={stl.gecko}
                  />
                  Coingecko
                </div>
                <div className={`${stl.block} ${stl.ray}`}>
                  <img
                    src="../Raydium.svg"
                    alt="Raydium"
                    className={stl.gecko}
                  />
                  Raydium
                </div>
                <div className={`${stl.block} ${stl.ray}`}>
                  <img
                    src="../Jupiter.png"
                    alt="Jupiter"
                    className={stl.gecko}
                  />
                  Jupiter
                </div>
                <div className={`${stl.block} ${stl.ray}`}>
                  <img
                    src="../Dextools.png"
                    alt="Dextools"
                    className={stl.gecko}
                  />
                  Dextools
                </div>
                <div className={`${stl.block} ${stl.ray}`}>
                  <img
                    src="../Birdeye.png"
                    alt="Birdeye"
                    className={stl.gecko}
                  />
                  Birdeye
                </div>
              </div>
              <div className={stl.bar}></div>
              <div className={stl.memeList}>
                <div className={stl.memeTile}>
                  <img src="../Duko.png" alt="Coin" className={stl.coinImg} />
                </div>
                <div className={stl.memeTile}>
                  <img src="../Epep.png" alt="Coin" className={stl.coinImg} />
                </div>
                <div className={stl.memeTile}>
                  <img src="../Wif.webp" alt="Coin" className={stl.coinImg} />
                </div>
                <div className={stl.memeTile}>
                  <img src="../Pepe.png" alt="Coin" className={stl.coinImg} />
                </div>
                <div className={stl.memeTile}>
                  <img src="../Samo.png" alt="Coin" className={stl.coinImg} />
                </div>
                <div className={stl.memeTile}>
                  <img src="../Bonk.webp" alt="Coin" className={stl.coinImg} />
                </div>

                <div className={stl.memeTile}>
                  <img src="../Bome.webp" alt="Coin" className={stl.coinImg} />
                </div>
                <div className={stl.memeTile}>
                  <img src="../Myro.webp" alt="Coin" className={stl.coinImg} />
                </div>
                <div className={stl.memeTile}>
                  <img
                    src="../Popcat.webp"
                    alt="Coin"
                    className={stl.coinImg}
                  />
                </div>
                <div className={stl.memeTile}>
                  <img src="../Ponke.webp" alt="Coin" className={stl.coinImg} />
                </div>
                <div className={stl.memeTile}>
                  <img src="../Peng.webp" alt="Coin" className={stl.coinImg} />
                </div>
                <div className={stl.memeTile}>
                  <img src="../Silly.png" alt="Coin" className={stl.coinImg} />
                </div>
                <div className={stl.memeTile}>
                  <img src="../Solama.png" alt="Coin" className={stl.coinImg} />
                </div>
                <div className={stl.memeTile}>
                  <img src="../Guac.png" alt="Coin" className={stl.coinImg} />
                </div>
                <div className={stl.memeTile}>
                  <img src="../MEW.png" alt="Coin" className={stl.coinImg} />
                </div>
                <div className={stl.memeTile}>
                  <img src="../SMOG.png" alt="Coin" className={stl.coinImg} />
                </div>
                <div className={stl.memeTile}>
                  <img src="../nub.png" alt="Coin" className={stl.coinImg} />
                </div>
                <div className={stl.memeTile}>
                  <img
                    src="../catwifbag.png"
                    alt="Coin"
                    className={stl.coinImg}
                  />
                </div>
              </div>
            </div>
          </div>
          <img src="../BarbieImg.webp" alt="Barbie" className={stl.barbie2} />
        </section>
        <section className={stl.pictureGrid}>
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
          >
            <Masonry>
              <img
                src="../BibbleGun.jpg"
                alt="Bibble"
                className={stl.masonImg}
              />
              <img
                src="https://static1.srcdn.com/wordpress/wp-content/uploads/2023/08/barbie-bible.jpg?q=50&fit=crop&w=1100&h=618&dpr=1.5"
                alt="Bibble"
                className={stl.masonImg}
              />

              <img
                src="https://m.media-amazon.com/images/I/81dYKYZmWGL.jpg"
                alt="Bibble"
                className={stl.masonImg}
              />
              <img
                src="https://m.media-amazon.com/images/I/61WuiVnLknL._AC_UF894,1000_QL80_.jpg"
                alt="Bibble"
                className={stl.masonImg}
              />
              <img
                src="https://images-ng.pixai.art/images/orig/1943e399-c82a-4dcb-bb78-5517c5a42cec"
                alt="Bibble"
                className={stl.masonImg}
              />
              <img
                src="https://content.instructables.com/FJN/J3IO/L8RCT8NP/FJNJ3IOL8RCT8NP.png?auto=webp&frame=1&width=1024&fit=bounds&md=af6ce972476e532664c3fb67b888a633"
                alt="Bibble"
                className={stl.masonImg}
              />
              <img
                src="https://cloudfront-us-east-1.images.arcpublishing.com/infobae/BRPMOML3WJBUFAONWOU2QKOJ7U.jpg"
                alt="Bibble"
                className={stl.masonImg}
              />
              <img
                src="https://i.pinimg.com/1200x/19/68/65/19686557ba15184163b572dfe94f2e15.jpg"
                alt="Bibble"
                className={stl.masonImg}
              />
              <img
                src="https://preview.redd.it/all-hail-bibble-v0-lb7edqtjpj6d1.jpeg?width=640&crop=smart&auto=webp&s=09b75bae1e2c9b9f24dbb1bdd44a5c2703dd97db"
                alt="Bibble"
                className={stl.masonImg}
              />
              <img
                src="https://i.pinimg.com/736x/89/22/3f/89223f71b60d95a86f5250bd10688e32.jpg"
                alt="Bibble"
                className={stl.masonImg}
              />
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBPRKuPBAy9_t7SDN5GPOFvZKCU87BYZqFMA&s"
                alt="Bibble"
                className={stl.masonImg}
              />
              <img src="../Bib1.jpg" alt="Bibble" className={stl.masonImg} />
              <img src="../Bib2.jpg" alt="Bibble" className={stl.masonImg} />
              <img src="../Bib3.jpg" alt="Bibble" className={stl.masonImg} />
              <img src="../Bib4.jpg" alt="Bibble" className={stl.masonImg} />
              <img src="../Bib5.jpg" alt="Bibble" className={stl.masonImg} />
              <img src="../Bib6.jpg" alt="Bibble" className={stl.masonImg} />
              <img src="../Bib14.webp" alt="Bibble" className={stl.masonImg} />
              <img src="../Bib7.jpg" alt="Bibble" className={stl.masonImg} />
              <img src="../Bib8.jpg" alt="Bibble" className={stl.masonImg} />
              <img src="../Bib9.jpg" alt="Bibble" className={stl.masonImg} />
              <img src="../Bib13.jpg" alt="Bibble" className={stl.masonImg} />
              <img src="../Bib10.jpg" alt="Bibble" className={stl.masonImg} />
              <img src="../Bib11.jpg" alt="Bibble" className={stl.masonImg} />
              <img src="../Bib12.jpg" alt="Bibble" className={stl.masonImg} />
              <img src="../Bib16.jpg" alt="Bibble" className={stl.masonImg} />
              <img src="../Bib15.jpg" alt="Bibble" className={stl.masonImg} />
            </Masonry>
          </ResponsiveMasonry>
        </section>
      </main>
      <footer className={stl.windows} onClick={() => setStartOpen(false)}>
        {openPaint && (
          <div className={stl.winModal}>
            <div className={stl.topBar}>
              <div className={stl.leftBlock}>
                <img
                  src="../Paint.webp"
                  alt="paint"
                  className={stl.microIcon}
                />
                <span className={stl.modalTitle}>Paint</span>
              </div>
              <div className={stl.rightBlock}>
                <button></button>
                <button></button>
                <button onClick={() => setOpenPaint(false)}></button>
              </div>
            </div>
            <div className={stl.modalBody}>
              <iframe
                src="https://jspaint.app/#local:b9160b688fefd"
                title="JS Paint App"
                style={{ border: "none", width: "100%", height: "100%" }}
                allowFullScreen
              />
            </div>
          </div>
        )}
        {openSolitaire && (
          <div className={stl.winModal}>
            <div className={stl.topBar}>
              <div className={stl.leftBlock}>
                <img
                  src="../Solitaire.png"
                  alt="paint"
                  className={stl.microIcon}
                />
                <span className={stl.modalTitle}>Solitaire</span>
              </div>
              <div className={stl.rightBlock}>
                <button></button>
                <button></button>
                <button onClick={() => setOpenSolitaire(false)}></button>
              </div>
            </div>
            <div className={stl.modalBody}>
              <iframe
                src="https://solitaires-online.com/"
                title="Solitaire App"
                style={{ border: "none", width: "100%", height: "100%" }}
                allowFullScreen
              />
            </div>
          </div>
        )}
        {mahjongOpen && (
          <div className={stl.winModal}>
            <div className={stl.topBar}>
              <div className={stl.leftBlock}>
                <img
                  src="../Mahjong.svg"
                  alt="paint"
                  className={stl.microIcon}
                />
                <span className={stl.modalTitle}>Mahjong</span>
              </div>
              <div className={stl.rightBlock}>
                <button></button>
                <button></button>
                <button onClick={() => setMahjongOpen(false)}></button>
              </div>
            </div>
            <div className={stl.modalBody}>
              <iframe
                src="https://mahjong-pro.com/"
                title="Solitaire App"
                style={{ border: "none", width: "100%", height: "100%" }}
                allowFullScreen
              />
            </div>
          </div>
        )}
        {barbieFanOpen && (
          <div className={stl.winModal}>
            <div className={stl.topBar}>
              <div className={stl.leftBlock}>
                <img
                  src="../BarbieImg.webp"
                  alt="Barbie"
                  className={stl.microIcon}
                />
                <span className={stl.modalTitle}>Barbie Fanpage</span>
              </div>
              <div className={stl.rightBlock}>
                <button></button>
                <button></button>
                <button onClick={() => setBarbieFanOpen(false)}></button>
              </div>
            </div>
            <div className={stl.modalBody}>
              <iframe
                src="https://www.barbiemedia.com/"
                title="Barbie App"
                style={{ border: "none", width: "100%", height: "100%" }}
                allowFullScreen
              />
            </div>
          </div>
        )}
        {doomOpen && (
          <div className={stl.winModal}>
            <div className={stl.topBar}>
              <div className={stl.leftBlock}>
                <img src="../Doom.png" alt="Doom" className={stl.microIcon} />
                <span className={stl.modalTitle}>Doom</span>
              </div>
              <div className={stl.rightBlock}>
                <button></button>
                <button></button>
                <button onClick={() => setDoomOpen(false)}></button>
              </div>
            </div>
            <div className={stl.modalBody}>
              <iframe
                src="https://bestdosgames.com/games/doom/play"
                title="Doom App"
                style={{ border: "none", width: "100%", height: "100%" }}
                allowFullScreen
              />
            </div>
          </div>
        )}
        {startOpen && (
          <div className={stl.menu}>
            <div className={stl.leftBar} onClick={(e) => e.stopPropagation()}>
              <img src="../Win95.webp" alt="Win95" className={stl.win95} />
              <div className={stl.startList}>
                <div
                  className={stl.startItem}
                  onClick={() => window.open(REACT_APP_LORE, "_blank")}
                >
                  <LuScrollText className={stl.startXIcon} />
                  <span className={stl.startSpan}>Lore</span>
                </div>
                <div className={stl.startItem} onClick={handleCopy}>
                  <FaRegCopy className={stl.startXIcon} />
                  <span className={stl.startSpan}>Contract Address</span>
                </div>
                <div
                  className={stl.startItem}
                  onClick={() => window.open(REACT_APP_BUY, "_blank")}
                >
                  <FaSackDollar className={stl.startXIcon} />
                  <span className={stl.startSpan}>Buy</span>
                </div>
                <div
                  className={stl.startItem}
                  onClick={() => window.open(REACT_APP_CHART, "_blank")}
                >
                  <FaChartColumn className={stl.startXIcon} />
                  <span className={stl.startSpan}>Chart</span>
                </div>
                <div
                  className={stl.startItem}
                  onClick={() => window.open(REACT_APP_TELEGRAM_URL, "_blank")}
                >
                  <BsTelegram className={stl.startXIcon} />
                  <span className={stl.startSpan}>Telegram</span>
                </div>
                <div
                  className={stl.startItem}
                  onClick={() => window.open(REACT_APP_TWITTER_URL, "_blank")}
                >
                  <BsTwitterX className={stl.startXIcon} />
                  <span className={stl.startSpan}>Twitter</span>
                </div>
                <div className={stl.border}></div>
                <div
                  className={stl.startItem}
                  onClick={() => (window.location.href = REACT_APP_TWITTER_URL)}
                >
                  <img
                    src="../ShutDown.png"
                    alt="Shutdown"
                    className={stl.startIcon}
                  />
                  <span className={stl.startSpan}>
                    Sh<u>u</u>tdown...
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={stl.iconsBar}>
          <div
            className={stl.iconDiv}
            onDoubleClick={() => setBarbieFanOpen(true)}
          >
            <img
              src="../BarbieImg.webp"
              alt="Barbie"
              className={stl.barbieIcon}
            />
            <span className={stl.iconTxt}>Barbie Fanpage</span>
          </div>
          <div className={stl.iconDiv} onDoubleClick={() => setDoomOpen(true)}>
            <img src="../Doom.png" alt="Doom" className={stl.doomIcon} />
            <span className={stl.iconTxt}>Doom</span>
          </div>
          <div
            className={stl.iconDiv}
            onDoubleClick={() => setMahjongOpen(true)}
          >
            <img
              src="../Mahjong.svg"
              alt="Mahjong"
              className={stl.mahjongIcon}
            />
            <span className={stl.iconTxt}>Mahjong</span>
          </div>
          <div
            className={stl.iconDiv}
            onDoubleClick={() => setOpenSolitaire(true)}
          >
            <img
              src="../Solitaire.png"
              alt="Solitaire"
              className={stl.solitaireIcon}
            />
            <span className={stl.iconTxt}>Solitaire</span>
          </div>
          <div className={stl.iconDiv} onDoubleClick={() => setOpenPaint(true)}>
            <img src="../Paint.webp" alt="paint" className={stl.deskIcon} />
            <span className={stl.iconTxt}>Paint</span>
          </div>
        </div>
        <div className={stl.startBar} onClick={(e) => e.stopPropagation()}>
          <button
            className={`${stl.startCta} ${startOpen ? stl.startActive : ""}`}
            onClick={() =>
              startOpen ? setStartOpen(false) : setStartOpen(true)
            }
          >
            <img src="../Start.jpg" alt="start" className={stl.startImg} />
          </button>
          <div
            className={stl.timeBox}
            onDoubleClick={() =>
              alert(
                "Crafted deep in the mountains of Fairytopia, Bibble is the all seeing eye of the Solana Ecosystem.\n\nEvery transaction, transfer, and withdrawal is seen and judged by Bibble.\n\nUsing deep Karmaic powers Bibble rewards those who commit good deeds and punishes those who jeet, and nuke charts."
              )
            }
          >
            <img src="../Clock.png" alt="clock" className={stl.clockIcon} />
            <span className={stl.currentTime}>{currentTime}</span>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;
